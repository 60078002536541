<template>
  <div class="update-detail-area px-1 py-2">
    <div class="fw-500 mb-2">{{ $t('order_update_detail') }}:</div>

    <!-- Item Has Been Updated -->
    <div v-if="amendedItem" class="d-flex flex-column">
      <div v-if="!isShowMore">
        <AmendedItem :item="amendedItem[0]" :userCurrency="userCurrency" />
      </div>
      <div v-else>
        <div v-for="(item, index) in amendedItem" :key="index">
          <AmendedItem :item="item" :userCurrency="userCurrency" />
        </div>
      </div>

      <!-- Show More and Show Less -->

      <ion-button
        v-if="amendedItem?.length > 1"
        class="mt-1 show-more-and-less text-capitalize"
        expand="block"
        fill="clear"
        @click="handleShowMoreAndLess"
      >
        {{ !isShowMore ? $t('show_more') : $t('show_less') }}
      </ion-button>
    </div>

    <!-- Delivery Info Updated -->
    <div v-if="deliveryAmend" class="d-flex flex-column">
      <ion-row class="my-3">
        <ion-icon class="mr-2 icons-24px" :icon="'/assets/images/track-order.svg'" />
        <ion-col class="d-flex flex-column set-top">
          <div class="d-flex flex-row mb-1">
            <ion-text class="mb-1 mr-2 fs-15px fw-400">
              {{ $t('delivery_time') }}
            </ion-text>
            <div
              v-if="showDeliveryDateAmends || showDeliveryTimeAmends"
              class="bg-status-item-update set-top"
              :class="orderStatusBackground('Updated')"
            >
              <div class="mr-1 dot" :class="orderStatusDot('Updated')"></div>
              <ion-text class="item-status-text" :class="orderStatusColor('Updated')">{{
                $t('track_order_update_status.updated')
              }}</ion-text>
            </div>
          </div>

          <span v-if="showDeliveryDateAmends">
            <ion-text class="text-grey middle-line-text">
              {{ setDeliveryDateInfo(getPreviousValueByName('Delivery Date')) }}
            </ion-text>
            <ion-icon class="mx-1 icons-15px min-mb" color="primary" :icon="arrowForwardOutline" />
            <ion-text class="text-grey">
              {{ setDeliveryDateInfo(getNewValueByName('Delivery Date')) }}
            </ion-text>
          </span>
          <span v-else>
            <ion-text class="text-grey"> {{ setDeliveryDateInfo(deliveryDate) }} </ion-text>
          </span>

          <span v-if="showDeliveryTimeAmends">
            <ion-text class="text-grey middle-line-text">
              {{ getPreviousValueByName('Delivery Time') }}
            </ion-text>
            <ion-icon class="mx-1 icons-15px min-mb" color="primary" :icon="arrowForwardOutline" />
            <ion-text class="text-grey"> {{ getNewValueByName('Delivery Time') }} </ion-text>
          </span>
          <span v-else>
            <ion-text class="text-grey"> {{ deliveryTime }} </ion-text>
          </span>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-icon class="mr-2 icons-24px" :icon="'/assets/images/location-outline.svg'" />
        <ion-col class="d-flex flex-column set-top">
          <div class="d-flex flex-row mb-1">
            <ion-text class="mb-1 mr-2 fs-15px fw-400">
              {{ $t('delivery_address') }}
            </ion-text>
            <div
              v-if="showDeliveryAddressAmends"
              class="bg-status-item-update set-top"
              :class="orderStatusBackground('Updated')"
            >
              <div class="mr-1 dot" :class="orderStatusDot('Updated')"></div>
              <ion-text class="item-status-text" :class="orderStatusColor('Updated')">{{
                $t('track_order_update_status.updated')
              }}</ion-text>
            </div>
          </div>

          <span v-if="showDeliveryAddressAmends">
            <ion-text class="text-grey middle-line-text">
              {{ getPreviousValueByName('Address') }}
            </ion-text>
            <ion-icon class="mx-1 icons-15px min-mb" color="primary" :icon="arrowForwardOutline" />
            <ion-text class="text-grey">
              {{ getNewValueByName('Address') }}
            </ion-text>
          </span>
          <span v-else>
            <ion-text class="text-grey"> {{ deliveryAddress }} </ion-text>
          </span>
        </ion-col>
      </ion-row>
    </div>
  </div>
</template>
<script>
import { ORDER_AMENDS_STATUS_NAME } from '@/modules/shared/constants';
import dayjs from 'dayjs';
import { arrowForwardOutline, readerOutline } from 'ionicons/icons';
import { computed, defineAsyncComponent, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  props: {
    amendedItem: {
      type: Object,
      default: function () {
        return {};
      }
    },

    deliveryAmend: {
      type: Array,
      default: function () {
        return [];
      }
    },

    deliveryDate: {
      type: String,
      default: ''
    },

    deliveryTime: {
      type: String,
      default: ''
    },

    deliveryAddress: {
      type: String,
      default: ''
    },

    userCurrency: {
      type: String,
      default: ''
    }
  },
  components: {
    // components
    AmendedItem: defineAsyncComponent(() => import('./AmendedItem.vue'))
  },
  setup(props) {
    const { t } = useI18n();
    // cek order status for set background status style
    const orderStatusBackground = (status) => {
      return status === ORDER_AMENDS_STATUS_NAME.NEW
        ? 'bg-green'
        : status === ORDER_AMENDS_STATUS_NAME.DELETED
        ? 'bg-red'
        : 'bg-orange';
    };

    // cek order status for set dot background status style
    const orderStatusDot = (status) => {
      return status === ORDER_AMENDS_STATUS_NAME.NEW
        ? 'green'
        : status === ORDER_AMENDS_STATUS_NAME.DELETED
        ? 'red'
        : 'orange';
    };
    // cek order status for set status title color
    const orderStatusColor = (status) => {
      return status === ORDER_AMENDS_STATUS_NAME.NEW
        ? 'text-green'
        : status === ORDER_AMENDS_STATUS_NAME.DELETED
        ? 'text-red'
        : 'text-orange';
    };

    const orderStatusName = (status) => {
      return status === ORDER_AMENDS_STATUS_NAME.NEW
        ? t('track_order_update_status.new')
        : status === ORDER_AMENDS_STATUS_NAME.DELETED
        ? t('track_order_update_status.removed')
        : status === ORDER_AMENDS_STATUS_NAME.REPLACED
        ? t('track_order_update_status.replaced')
        : t('track_order_update_status.updated');
    };
    const isShowMore = ref(false);
    const handleShowMoreAndLess = () => {
      isShowMore.value = !isShowMore.value;
    };

    const getNewValueByName = (nameToFind) => {
      for (const amend of props.deliveryAmend) {
        if (amend.name === nameToFind) {
          return amend.new_value;
        }
      }
      return null; // Return null if the name is not found
    };

    const getPreviousValueByName = (nameToFind) => {
      for (const amend of props.deliveryAmend) {
        if (amend.name === nameToFind) {
          return amend.previous_value;
        }
      }
      return null; // Return null if the name is not found
    };

    const showDeliveryDateAmends = computed(() => {
      return getNewValueByName('Delivery Date') !== null;
    });

    const showDeliveryTimeAmends = computed(() => {
      return getNewValueByName('Delivery Time') !== null;
    });

    const showDeliveryAddressAmends = computed(() => {
      return getNewValueByName('Address') !== null;
    });

    const setDeliveryDateInfo = (deliveryDate) => {
      return dayjs(deliveryDate).format('ddd, D MMMM YYYY');
    };

    return {
      arrowForwardOutline,
      readerOutline,
      isShowMore,
      handleShowMoreAndLess,
      orderStatusBackground,
      orderStatusDot,
      orderStatusColor,
      orderStatusName,
      getNewValueByName,
      getPreviousValueByName,
      showDeliveryDateAmends,
      showDeliveryTimeAmends,
      showDeliveryAddressAmends,
      setDeliveryDateInfo
    };
  }
});
</script>
<style lang="scss" scoped>
.update-detail-area {
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  border: 1px dashed #e0e0e0;
  border-radius: 6px;
  height: fit-content;
}
.text-grey {
  color: #9e9e9e;
}

.middle-line-text {
  text-decoration: line-through;
  text-decoration-line: middle;
}

.set-top {
  margin-top: -6px;
}
</style>

<style src="../style.scss" lang="scss" scoped></style>
